import { PropsWithChildren } from 'react';
import InfoDrawerDivider from './InfoDrawerDivider';
import { Stack, styled } from '@mui/material';
import { Typography } from '../../Typography';

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
}));

export default function HelpSection({
  label,
  children,
}: PropsWithChildren<{ label: string }>) {
  return (
    <>
      <Typography variant='body1' fontWeight={600}>
        {label}
      </Typography>
      <Container divider={<InfoDrawerDivider />}>{children}</Container>
    </>
  );
}
