import {
  ENABLE_GPU_FIELD_TITLE,
  FeatureNames,
  GPU_SPEC_FIELD_TITLE,
  SESSION_DURATION_FIELD_TITLE,
  STORAGE_FIELD_TITLE,
  useAllowBlockQuotas,
  VM_SPEC_FIELD_TITLE,
} from '@formbio/api';

const VM_BASIC_LEVELS = ['xs', 's'];
const INDIVIDUAL_STORAGE = ['500G'];
const INDIVIDUAL_SESSION_DURATION = ['2h'];

/**
 * Centralizes the logic for checking if a feature is allowed, not allowed, or
 * reached its quota (// TODO not implemented yet)
 * @returns {isEnabled} a function that takes a value and a feature name and
 * returns true if the value is allowed for the feature or if the feature is
 * not included in the quotas (eg. no quotas for the org)
 */
export default function useVMQuotas() {
  const { quotas, passesQuota } = useAllowBlockQuotas();

  function checkBasicComputeQuota(baseLevels: string[], spec: string) {
    if (baseLevels.includes(spec)) {
      return passesQuota(FeatureNames.BASIC_COMPUTE);
    }
    return passesQuota(FeatureNames.PREMIUM_COMPUTE);
  }

  /**
   * The quota mostly indicates that the feature is enabled. The meaning of
   * enabled varies for each FeatureName. For example, for BASIC_COMPUTE
   * means that only xs and s VMs are allowed. For PREMIUM_COMPUTE means that
   * all VMs are allowed.
   *
   * First we need to determine the feature "category" (eg. VM Specification, Storage, etc.)
   * This is basically a mapping from the field title to the feature name(s).
   *
   * Most features will need a custom rule.
   * Add more rules here. // TODO
   * @param fieldId the input id that will be mapped to a feature constraint
   */
  function isEnabled(fieldTitle: string, fieldId: string | number) {
    if (!quotas) {
      // TODO is it a problem for orgs without quotas?
      return true;
    }
    switch (fieldTitle) {
      case VM_SPEC_FIELD_TITLE:
        return checkBasicComputeQuota(VM_BASIC_LEVELS, String(fieldId));
      case GPU_SPEC_FIELD_TITLE: // GPU Specification
      case ENABLE_GPU_FIELD_TITLE:
        return passesQuota(FeatureNames.PREMIUM_COMPUTE);
      case SESSION_DURATION_FIELD_TITLE:
        return checkBasicComputeQuota(
          INDIVIDUAL_SESSION_DURATION,
          String(fieldId),
        );
      case STORAGE_FIELD_TITLE:
        return checkBasicComputeQuota(INDIVIDUAL_STORAGE, String(fieldId));

      // unhandled fieldId
      default:
        return true;
    }
  }

  return { isEnabled, quotas };
}
