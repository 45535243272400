import {
  CONTAINER_APP_TYPE,
  ContainerApp,
  ContainerAppCategory,
  ContainerAppInstance,
  ContainerAppInstanceStatus,
  VM_SPEC_FIELD_TITLE,
  WorkflowSchemaVisibility,
} from '@formbio/api';
import { ActorType } from '@formbio/api/src/types/actor';
import { getLaterDate } from '@formbio/ui/utils/datetime';
import { v4 as uuidv4 } from 'uuid';

export const containerApps: ContainerApp[] = [
  {
    appName: 'rstudio',
    createdBy: 'myUser',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    deletedAt: '',
    visibility: WorkflowSchemaVisibility.Public,
    access: 'rw',
    type: CONTAINER_APP_TYPE.rstudio,
    frn: 'myOrg/myProj/rstudio',
    config: { gpu: { enabled: false } },
    schema: {
      id: 'rstudio',
      category: ContainerAppCategory.EXPLORE,
      description:
        'RStudio is an integrated development environment (IDE) for R and Python.',
      displayName: 'RStudio IDE',
      port: '8787',
      container: 'formbio-rstudio-server',
      mount: '/Files',
      command:
        'sudo docker run -d --restart unless-stopped -e ROOT=true -p 127.0.0.1:8787:8787 -v /home/ubuntu/tmp/:/home/rstudio/tmp:rshared  -v /home/ubuntu/project/:/home/rstudio/Files:rshared --name formbio-rstudio-server -e DISABLE_AUTH=true $RSTUDIO_IMAGE',
      inputs: {
        vmSpec: {
          title: VM_SPEC_FIELD_TITLE,
          required: true,
          type: 'string',
          format: 'enum',
          default: '',
          enum: [
            {
              id: 'xs',
              name: 'Extra Small - 4 vCPU - 16 GB',
            },
            {
              id: 's',
              name: 'Small - 8 vCPU - 32 GB',
            },
            {
              id: 'm',
              name: 'Medium - 16 vCPU - 64 GB',
            },
            {
              id: 'l',
              name: 'Large - 32 vCPU - 128 GB',
            },
            {
              id: 'xl',
              name: 'Extra Large - 64 vCPU - 256 GB',
            },
          ],
        },
        storage: {
          title: 'Storage',
          required: true,
          type: 'string',
          format: 'enum',
          default: '500GB',
          enum: [
            {
              id: '500GB',
              name: '500 GB',
            },
            {
              id: '1TB',
              name: '1 TB',
            },
            {
              id: '2TB',
              name: '2 TB',
            },
            {
              id: '3TB',
              name: '3 TB',
            },
          ],
        },
        sessionDuration: {
          title: 'Session Duration',
          required: true,
          type: 'string',
          format: 'enum',
          default: '8h',
          enum: [
            {
              id: '2h',
              name: '2 Hours',
            },
            {
              id: '4h',
              name: '4 Hours',
            },
            {
              id: '8h',
              name: '8 Hours',
            },
            {
              id: '12h',
              name: '12 Hours',
            },
            {
              id: '24h',
              name: '24 Hours',
            },
          ],
        },
      },
      ui: {
        inputs: ['vmSpec', 'storage', 'sessionDuration'],
      },
    },
  },
  {
    appName: 'cellxgene',
    createdBy: 'myUser',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    deletedAt: '',
    visibility: WorkflowSchemaVisibility.Public,
    access: 'ro',
    type: CONTAINER_APP_TYPE.rshiny,
    frn: 'myOrg/myProj/cellxgene',
    config: { gpu: { enabled: false } },
    schema: {
      id: 'cellxgene',
      category: ContainerAppCategory.VIZ,
      description:
        'A PyPI package to explore and annotate single cell datasets and explore unpublished single cell data.',
      displayName: 'CZ CELLxGENE Annotate',
      container: 'formbio-cellxgene',
      port: '5005',
      mount: '/Files',
      command:
        'sudo docker run -v ${PWD}:/data/ -p 5005:5005 cellxgene launch --host 0.0.0.0 /data/${FILENAME}',
      inputs: {
        inputFile: {
          title: 'Input File',
          required: true,
          type: 'string',
          format: 'file',
          filetype: '.h5ad',
        },
        vmSpec: {
          title: VM_SPEC_FIELD_TITLE,
          required: true,
          hidden: true,
          type: 'string',
          format: 'enum',
          default: 'xs',
          enum: [
            {
              id: 'xs',
              name: 'Extra Small - 4 vCPU - 16 GB',
            },
            {
              id: 's',
              name: 'Small - 8 vCPU - 32 GB',
            },
            {
              id: 'm',
              name: 'Medium - 16 vCPU - 64 GB',
            },
            {
              id: 'l',
              name: 'Large - 32 vCPU - 128 GB',
            },
            {
              id: 'xl',
              name: 'Extra Large - 64 vCPU - 256 GB',
            },
          ],
        },
        storage: {
          title: 'Storage',
          required: true,
          hidden: true,
          type: 'string',
          format: 'enum',
          default: '500GB',
          enum: [
            {
              id: '500GB',
              name: '500 GB',
            },
            {
              id: '1TB',
              name: '1 TB',
            },
            {
              id: '2TB',
              name: '2 TB',
            },
            {
              id: '3TB',
              name: '3 TB',
            },
          ],
        },
        sessionDuration: {
          title: 'Session Duration',
          required: true,
          type: 'string',
          format: 'enum',
          default: '8h',
          enum: [
            {
              id: '2h',
              name: '2 Hours',
            },
            {
              id: '4h',
              name: '4 Hours',
            },
            {
              id: '8h',
              name: '8 Hours',
            },
            {
              id: '12h',
              name: '12 Hours',
            },
            {
              id: '24h',
              name: '24 Hours',
            },
          ],
        },
      },
      ui: {
        inputs: ['inputFile', 'vmSpec', 'storage', 'sessionDuration'],
      },
    },
  },
];

export const containerAppInstances: ContainerAppInstance[] = Object.values(
  ContainerAppInstanceStatus,
).reduce<ContainerAppInstance[]>((instances, status) => {
  return [
    ...instances,
    ...Object.values(CONTAINER_APP_TYPE).map((type, index) => ({
      id: uuidv4(),
      name: `${type} ${index}`,
      displayName: `${type}-${index}`,
      status,
      createdAt: new Date().toISOString(),
      updatedAt: getLaterDate(new Date(), {
        minutes: 30,
      }).toISOString(),
      endsAt: getLaterDate(new Date(), {
        hours: index + 1,
      }).toISOString(),
      actor: {
        type: ActorType.USER,
        id: 'XXXXX',
        name: 'Jane Doe',
      },
      errorLog: '',
      errorMessage: '',
    })),
  ];
}, []);
