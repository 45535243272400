import Linkify from 'linkify-react';
import { ReactNode } from 'react';

export default function LinkifyComponent({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Linkify options={{ target: '_blank', ignoreTags: ['span'] }}>
      {children}
    </Linkify>
  );
}
