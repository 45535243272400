import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkHtml from 'remark-html';
import { markdownTextComponents } from '../elements/textComponents';
import rehypeRaw from 'rehype-raw';

export default function MarkdownWrapper({
  content,
  components = markdownTextComponents,
}: {
  content: string;
  components?: Components;
}) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkHtml]}
      components={components}
      rehypePlugins={[rehypeRaw]}
    >
      {content}
    </ReactMarkdown>
  );
}
