import { ReactNode } from 'react';
import { Typography } from '../../Typography';
import { styled } from '@mui/material';
import LinkifyComponent from '../../Link/LinkifyComponent';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export default function FieldHelpItem({
  title,
  helpText,
  isGroup = false,
}: {
  title: string;
  helpText?: ReactNode;
  isGroup?: boolean;
}) {
  return (
    <Container>
      <Typography variant={isGroup ? 'body1' : 'body2'} fontWeight={500}>
        {title}
      </Typography>
      <Typography variant='body3' fontWeight={400} whiteSpace='pre-line'>
        <LinkifyComponent>{helpText}</LinkifyComponent>
      </Typography>
    </Container>
  );
}
