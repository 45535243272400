import { LinearProgressProps, styled } from '@mui/material';
import { LinearProgress } from '.';

// loading bar styles that will remove jumping effect
export const StyledLinearProgress = styled(LinearProgress)({
  height: 4,
  marginBottom: -4,
});

export default function Loading(progressProps: LinearProgressProps) {
  return <StyledLinearProgress {...progressProps} />;
}
