import { Info as InfoIcon } from '@formbio/ui/components/Icons';
import { PropsWithChildren, useState } from 'react';
import InfoDrawerDivider from './InfoDrawerDivider';
import { ArrowLineRight as ArrowLineRightIcon } from '@formbio/ui/components/Icons';
import { CSSObject, Drawer, styled, Theme } from '@mui/material';
import ScrollableChildContainer, {
  ScrollableChild,
} from '../../ScrollableContainer';
import { Typography } from '../../Typography';
import { IconButton } from '../../IconButton';

const drawerWidth = 350;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Container = styled('div')(() => ({
  height: '100%',
}));

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${theme.palette.neutral[300]}`,
  boxSizing: 'border-box',
  '.MuiDrawer-paper': {
    backgroundColor: theme.palette.primary[100],
    position: 'relative',
    zIndex: 1000,
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
  ...(open ? openedMixin(theme) : closedMixin(theme)),
}));

const ClosedInfoContainer = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: theme.spacing(2),
  '.info-icon': {
    height: '20px',
    width: '20px',
    color: theme.palette.neutral[600],
  },
}));

const StyledHeader = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.neutral[100],
  paddingLeft: open ? theme.spacing(3) : 'auto',
  paddingRight: open ? theme.spacing(2) : 'auto',
  gap: theme.spacing(1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  '.info-icon': {
    height: '20px',
    width: '20px',
    color: theme.palette.neutral[600],
    display: open ? 'block' : 'none',
  },
  '.info-title': {
    display: open ? 'block' : 'none',
  },
  '.info-arrow-container': {
    marginLeft: 'auto',
    marginRight: open ? 0 : 'auto',
  },
  '.info-arrow': {
    color: theme.palette.neutral[600],
    transform: `rotate(${open ? '0deg' : '180deg'})`,
    fontSize: theme.spacing(2),
    margin: theme.spacing(0.5),
  },
}));

export default function InfoDrawer({
  title = 'Info',
  children,
}: PropsWithChildren<{ title?: string; height?: string }>) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleIsOpen = () => {
    setIsOpen(open => !open);
  };

  return (
    <Container>
      <StyledDrawer variant='permanent' open={isOpen}>
        <ScrollableChildContainer>
          <StyledHeader open={isOpen}>
            <InfoIcon className='info-icon' />
            <Typography className='info-title' variant='h4' component='span'>
              {title}
            </Typography>
            <IconButton className='info-arrow-container' onClick={toggleIsOpen}>
              <ArrowLineRightIcon className='info-arrow' />
            </IconButton>
          </StyledHeader>
          <InfoDrawerDivider />
          {isOpen ? (
            <ScrollableChild>{children}</ScrollableChild>
          ) : (
            <ClosedInfoContainer>
              <InfoIcon className='info-icon' />
            </ClosedInfoContainer>
          )}
        </ScrollableChildContainer>
      </StyledDrawer>
    </Container>
  );
}
